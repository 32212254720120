import { AxiosResponse } from 'axios';

import { IReleaseNote } from '@interfaces/IReleaseNotes';
import { AxiosInstance } from './axiosInstance';

export class ReleaseNotesAPIs {
    async getReleaseNotes(): Promise<AxiosResponse<IReleaseNote>> {
        return await AxiosInstance.get<IReleaseNote>('/release-notes');
    }
}

export const releaseNotesAPIs = new ReleaseNotesAPIs();
