import { ITrgMethod } from '@interfaces/trg-method.interface';
import { ITrgReport } from '@interfaces/trg-report.interface';
import { ITrg } from '@interfaces/trg.interface';
import { AxiosResponse } from 'axios';
import { AxiosInstance } from './axiosInstance';

class TrgAPI {
    async getMethods(): Promise<AxiosResponse<ITrgMethod[]>> {
        return await AxiosInstance.get('/trg/methods');
    }

    async getTrgs(): Promise<
        AxiosResponse<{ is_restricted: boolean; trgs: ITrg[] }>
    > {
        return await AxiosInstance.get('v1/trgs');
    }

    async insertTrgPoint(
        trgId: string,
        key: string,
        point: { x: number; y: number }
    ): Promise<AxiosResponse<ITrg>> {
        return await AxiosInstance.post(`trg/${trgId}/insert/new-point`, {
            key,
            point,
        });
    }

    async removeTrgPoint(
        trgId: string,
        point: string
    ): Promise<AxiosResponse<Omit<ITrg, 'ruler'>>> {
        return await AxiosInstance.delete(`trg/${trgId}/remove/point`, {
            params: { point },
        });
    }

    async updateTrgPoint(
        trgId: string,
        key: string,
        point: { x: number; y: number }
    ): Promise<AxiosResponse<ITrg>> {
        return AxiosInstance.put(`trg/${trgId}/update/point`, {
            key,
            point,
        });
    }

    async create(data: FormData, patientId: string): Promise<AxiosResponse> {
        return await AxiosInstance.post('/trg/new', data, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=photo',
            },
            params: {
                patientId,
            },
            timeout: 0,
        });
    }

    async one(trgId: string): Promise<AxiosResponse> {
        return await AxiosInstance.get(`/trg/one/${trgId}`, {
            timeout: 0,
        });
    }

    async update(data: ITrg, trgId: string): Promise<AxiosResponse<ITrg>> {
        return await AxiosInstance.put(`/trg/one/${trgId}`, data, {
            timeout: 0,
        });
    }

    async calculate(id: string, method_id: string): Promise<AxiosResponse> {
        return await AxiosInstance.get(`/trg/calculate/${id}`, {
            params: {
                method_id,
            },
        });
    }

    async trace(
        traceId: string
    ): Promise<AxiosResponse<{ id: string; state: string }>> {
        return await AxiosInstance.post(`/trg/trace/${traceId}`);
    }

    async checkTraceStatus(
        id: string
    ): Promise<AxiosResponse<{ state: string }>> {
        return await AxiosInstance.get(`/trg/trace/${id}`);
    }

    async getReport(id: string): Promise<AxiosResponse<ITrgReport>> {
        return await AxiosInstance.get(`/trg/results/${id}`);
    }

    async updateTrgResult(id: string, description: string) {
        return await AxiosInstance.put(`/trg/results/${id}`, { description });
    }

    async setTrgMethod(trg: string, method: string) {
        return await AxiosInstance.put(`/trg/set-method`, {
            trg,
            method,
        });
    }

    // V2
    // ----------------------------------------------------------------------
    async getTrg(trgId: string): Promise<AxiosResponse<ITrg>> {
        return await AxiosInstance.get(`/v2/trgs/${trgId}`);
    }

    async insertTrgRulerPoint(
        trgId: string,
        pointName: 'm1' | 'm0',
        x: number,
        y: number
    ): Promise<AxiosResponse<{ [key: string]: { x: number; y: number } }>> {
        return await AxiosInstance.put(`/v2/trgs/${trgId}/points/ruler`, {
            key: pointName,
            point: { x, y },
        });
    }
}

export const TrgAPIs = new TrgAPI();
