import axios, { AxiosResponse } from 'axios';
import { FormDataType } from '@components/registration/form/RegistrationForm';
import { IUserProfile } from '@interfaces/authentication';
import { AxiosInstance } from './axiosInstance';
import { toast } from 'react-toastify';

export class AuthorizationApis {
    static async getJWT(
        email: string,
        password: string
    ): Promise<
        AxiosResponse<{ roles: string[]; subscription: boolean; token: string }>
    > {
        return await AxiosInstance.post(`/auth/login`, {
            email,
            password,
        });
    }

    static async createAccount({
        email,
        password,
        name,
        surname,
    }: FormDataType): Promise<{ data: string; status: number } | void> {
        return await AxiosInstance.post(`/auth/register`, {
            email,
            password,
            name,
            surname,
        })
            .then(async (res) => {
                if ([200, 201, 204, 304].includes(res.status)) {
                    return await AuthorizationApis.getJWT(email, password).then(
                        (res) => {
                            if (
                                res.data.token &&
                                [200, 201, 204, 304].includes(res.status)
                            ) {
                                return {
                                    data: res.data.token,
                                    status: res.status,
                                };
                            }
                        }
                    );
                }
                throw new Error(res.data);
            })
            .catch((error) => {
                if (axios.isAxiosError(error)) {
                    console.error(error);
                    if (error.response) {
                        toast.error(error.response.data.message);
                        return {
                            data: error.response.data.message,
                            status: error.response.status,
                        };
                    }
                } else {
                    console.error(error);
                    throw new Error();
                }
            });
    }

    static async getProfile(): Promise<{
        data: IUserProfile;
        status: number;
    }> {
        return await AxiosInstance.get('/profile')
            .then(({ data, status }) => {
                if ([200, 201, 204, 304].includes(status)) {
                    return { data, status };
                }
                throw new Error(data.message);
            })
            .catch((error: unknown) => {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        toast.error(error.response.data.message);
                        if (error.response.status === 401) {
                            window.localStorage.removeItem('token');
                            location.reload();
                        }
                    }
                    throw new Error(error.message);
                } else {
                    throw new Error();
                }
            });
    }

    static async sendResetVerificationCode(
        email: string
    ): Promise<AxiosResponse> {
        return await AxiosInstance.post(
            `/auth/reset`,
            { email },
            {
                params: { step: 'reset_request' },
            }
        );
    }

    static async checkVerificationCode(
        email: string,
        code: string
    ): Promise<AxiosResponse<{ error: string; message?: string }>> {
        return await AxiosInstance.post(
            `/auth/reset`,
            {
                email,
                code,
            },
            {
                params: { step: 'verification_code' },
            }
        );
    }

    static async saveNewPassword(
        email: string,
        password: string
    ): Promise<AxiosResponse> {
        return await AxiosInstance.post(
            `/auth/reset`,
            {
                email,
                password,
            },
            {
                params: { step: 'new_password' },
            }
        );
    }
}
