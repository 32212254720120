import {
    ICollection,
    ITemplate,
    ICollectionPreview,
    ICollectionGroup,
    ICollectionCreateResponse,
} from '@interfaces/collection.interface';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { AxiosInstance } from './axiosInstance';
import { IConnectedToCollectionPresentation } from '@interfaces/presentation.interface';

// TODO: по мере использования запросов убирать статичность у них и вызывать именно экземпляр класса с методами

export class PhotoCollectionApis {
    static async getPatientCollections(
        id: string
    ): Promise<AxiosResponse<ICollection[]>> {
        return await AxiosInstance.get(`/collection/list/${id}`);
    }

    static async getCollection(
        collectionId: string,
        patient_id: string
    ): Promise<AxiosResponse<ICollection>> {
        return await AxiosInstance.get(`/collection/${collectionId}/one`, {
            params: {
                id: patient_id,
            },
        });
    }

    static async addCollection(
        patientId: string,
        collectionId: string
    ): Promise<{
        data: ICollection[];
    }> {
        try {
            const { status, data } = await AxiosInstance.post(
                `/collection/add`,
                {
                    patientId,
                    collectionId,
                }
            );

            if ([200, 201, 204, 304].includes(status)) {
                return { data };
            }
            throw new Error(data.message);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                toast.error(error.message);
                throw new Error(error.message);
            } else {
                throw new Error(
                    (error as unknown as { message: string }).message
                );
            }
        }
    }

    static async getAvailableCollections(): Promise<{
        data: ICollection[];
    }> {
        try {
            const { status, data } = await AxiosInstance.get(
                `/collection/available`
            );

            if ([200, 204, 304].includes(status)) {
                return { data };
            }
            throw new Error(data.message);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                toast.error(error.message);
                throw new Error(error.message);
            } else {
                throw new Error(
                    (error as unknown as { message: string }).message
                );
            }
        }
    }

    static async removeCollection(
        patientId: string,
        collectionId: string
    ): Promise<void> {
        try {
            const { status, data } = await AxiosInstance.delete(
                `/patient/collection`,
                {
                    data: {
                        patientId,
                        collectionId,
                    },
                }
            );

            if (![200, 201, 204, 304].includes(status)) {
                throw new Error(data.message);
            }
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                toast.error(error.message);
                throw new Error(error.message);
            } else {
                throw new Error(
                    (error as unknown as { message: string }).message
                );
            }
        }
    }

    static async resetElement(elementId: string): Promise<void> {
        try {
            const { status, data } = await AxiosInstance.put(
                `/collection/reset-element`,
                {
                    elementId,
                }
            );

            if (![200, 201, 204, 304].includes(status)) {
                throw new Error(data.message);
            }
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                toast.error(error.message);
                throw new Error(error.message);
            } else {
                throw new Error(
                    (error as unknown as { message: string }).message
                );
            }
        }
    }

    // Новые методы

    static async getTemplates(): Promise<{ data: ITemplate[] }> {
        try {
            const { status, data } = await AxiosInstance.get(
                `/collections/templates`
            );

            if ([200, 204, 304].includes(status)) {
                return { data };
            }
            throw new Error(data.message);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                toast.error(error.message);
                throw new Error(error.message);
            } else {
                throw new Error(
                    (error as unknown as { message: string }).message
                );
            }
        }
    }

    static async getAll(
        id: string
    ): Promise<AxiosResponse<ICollectionPreview[]>> {
        return await AxiosInstance.get('/collections', {
            params: {
                patient_id: id,
            },
        });
    }

    static async createCollection(
        patient_id: string,
        template_id: string
    ): Promise<AxiosResponse<ICollectionCreateResponse[]>> {
        const params: Record<string, string> = {
            patient_id: patient_id,
            template_id: template_id,
        };

        return await AxiosInstance.post(`/collections`, null, {
            params: params,
        });
    }

    static async deleteCollection(
        collection_id: string
    ): Promise<AxiosResponse> {
        return await AxiosInstance.delete(`/collections`, {
            params: {
                collection_id,
            },
        });
    }

    async getOne(
        collectionId: string,
        presentationId?: string | null
    ): Promise<AxiosResponse<ICollectionGroup>> {
        const params: Record<string, string> | undefined = presentationId
            ? { presentation_id: presentationId }
            : undefined;
        return await AxiosInstance.get(`/collections/${collectionId}`, {
            params: params,
        });
    }

    static async getCollectionData(
        parentId: string,
        collectionId: string,
        patientId: string,
        presentationId?: string | null
    ): Promise<AxiosResponse<ICollection>> {
        const params: Record<string, string> = {
            patient_id: patientId,
        };

        if (presentationId) {
            params.presentation_id = presentationId;
        }

        return await AxiosInstance.get(
            `/collections/${parentId}/photo-collections/${collectionId}`,
            {
                params: params,
            }
        );
    }

    static async connectFolder(
        collectionId: string,
        photoCollectionId: string,
        folder_id: string
    ): Promise<AxiosResponse<unknown>> {
        return await AxiosInstance.get(
            `/collections/${collectionId}/photo-collections/${photoCollectionId}/connect-folder`,
            {
                params: {
                    folder_id,
                },
            }
        );
    }

    static async updateCollectionPhoto(
        elementId: string,
        photoId: string,
        collectionId: string
    ): Promise<AxiosResponse<ICollection>> {
        return await AxiosInstance.put(
            `/collections/${collectionId}/photo-collections/photo`,
            {
                element_id: elementId,
                photo_id: photoId,
            }
        );
    }

    static async unlinkPhoto(
        collectionId: string,
        elementId: string
    ): Promise<AxiosResponse> {
        return await AxiosInstance.delete(
            `/collections/${collectionId}/photo-collections/photo`,
            {
                data: {
                    element_id: elementId,
                },
            }
        );
    }

    async getConnectedPresentations(
        collectionId: string
    ): Promise<AxiosResponse<IConnectedToCollectionPresentation[]>> {
        return await AxiosInstance.get(
            `/collections/${collectionId}/presentations`
        );
    }
}

export const photoCollectionAPIs = new PhotoCollectionApis();
