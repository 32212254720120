import { deleteFile } from '@components/photoArchive/common/ArchiveItemMenu/actions';
import {
    IArchiveCollection,
    IFolderTemplate,
} from '@interfaces/collection.interface';
import axios, { AxiosResponse } from 'axios';
import { AxiosInstance } from './axiosInstance';

interface IFolderCreation {
    template_id: string;
    parent_id?: string;
    patient_id?: string;
}

export class PhotoArchiveApis {
    static async getTemplates(): Promise<
        AxiosResponse<{ key: string; id: string }[]>
    > {
        return await AxiosInstance.get('/store/folders/templates');
    }

    static async getFolders(
        id: string
    ): Promise<AxiosResponse<IArchiveCollection[]>> {
        return await AxiosInstance.get(`/store/folders`, {
            params: {
                patient_id: id,
            },
            headers: {
                'Content-type': 'application/json; charset=utf-8',
            },
        });
    }

    static async createFolder(
        params: IFolderCreation
    ): Promise<AxiosResponse<unknown>> {
        return await AxiosInstance.post(`/store/folders`, params);
    }

    static async addSubfolder(
        parentId: string,
        template: string
    ): Promise<AxiosResponse<unknown>> {
        return await AxiosInstance.post(`/collection/sub-folder/create`, {
            parent_id: parentId,
        });
    }

    static async getFolder(
        folderId: string
    ): Promise<AxiosResponse<IArchiveCollection>> {
        return await AxiosInstance.get(`/store/folders/${folderId}`);
    }

    static async updateFolder(
        folderId: string,
        data: {
            description?: string;
            title?: string;
            style?: {
                bg_color: string;
            };
        }
    ): Promise<AxiosResponse<IArchiveCollection>> {
        return await AxiosInstance.put(`/store/folders/${folderId}`, data);
    }

    static async renameFolder(
        folderId: string,
        parentId: string,
        description: string,
        title: string
    ): Promise<AxiosResponse<unknown>> {
        return await AxiosInstance.put(`/store/folders/${folderId}`, {
            description: description,
            title: title,
        });
    }

    static async deleteFolder(folderId: string): Promise<AxiosResponse<void>> {
        return await AxiosInstance.delete(`/store/folders/${folderId}`);
    }

    static async deleteFile(fileId: string): Promise<AxiosResponse<void>> {
        return await AxiosInstance.delete(`/store/files/${fileId}`);
    }
}
