import { AxiosInstance } from '@APIs/axiosInstance';
import { AxiosResponse } from 'axios';
import { ITutorial } from '@interfaces/tutorials.interface';

class TutorialsAPI {
    async getTutorial(
        title: 'trg' | 'presentation' | 'collection'
    ): Promise<AxiosResponse<ITutorial>> {
        return await AxiosInstance.get('/tutorials', { params: { title } });
    }
}

export const TutorialsAPIs = new TutorialsAPI();
