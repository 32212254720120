import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Registration from '@components/registration/Registration';
import SignInPage from '@components/login/LoginPage';
import { Menu as MenuComponent } from '@components/common/menu/Menu';

import { ROUTES } from '@constants/routes';
import MailVerificationPage from '@components/verification/MailVerificationPage';
import PrivateRoute from '@components/PrivateRoute';
import ResetPasswordPage from '@components/resetpassword/ResetPasswordPage';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const App = (): JSX.Element => {
    return (
        <div>
            <ToastContainer position="bottom-left" />
            <Switch>
                <Route path={ROUTES.SIGN_UP.END} component={Registration} />
                <Route path={ROUTES.LOGIN.END} component={SignInPage} />
                <Route path={ROUTES.RESET.END} component={ResetPasswordPage} />
                <Route
                    path={ROUTES.VERIFY.END}
                    component={MailVerificationPage}
                />
                <PrivateRoute
                    path={ROUTES.ROOT.END}
                    component={MenuComponent}
                />
            </Switch>
        </div>
    );
};

export default App;
