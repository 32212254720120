import {
    IAnnouncements,
    IPresentation,
} from '@interfaces/presentation.interface';
import { AxiosResponse } from 'axios';
import { AxiosInstance } from './axiosInstance';
import {
    IArrow,
    ILine,
    IRectangle,
    PresentationFiguresEnum,
} from '@interfaces/presentationEditing.interface';

export class PresentationApis {
    async getPresentations(): Promise<
        AxiosResponse<{
            is_restricted: boolean;
            presentations: IPresentation[];
        }>
    > {
        return await AxiosInstance.get('v1/presentations');
    }

    async createPresentation(
        templateId: string,
        patientId: string,
        collectionId: string
    ): Promise<AxiosResponse<IPresentation>> {
        return await AxiosInstance.post('/presentation/create', {
            presentationID: templateId,
            patientId,
            collectionId,
        });
    }

    async getPresentation(
        id: string,
        screenWidth?: number,
        screenHeight?: number
    ) {
        return await AxiosInstance.get('/presentation/full', {
            params:
                screenWidth && screenHeight
                    ? {
                          id,
                          screenWidth,
                          screenHeight,
                      }
                    : { id },
        });
    }

    async getAnnouncements(id: string): Promise<AxiosResponse<IAnnouncements>> {
        return await AxiosInstance.get('/presentation/announcements/', {
            params: { id },
        });
    }

    async updatePresentation(
        presentationId: string,
        title: string
    ): Promise<AxiosResponse> {
        return await AxiosInstance.put(
            `/presentation/update/${presentationId}`,
            { name: title }
        );
    }

    async setPresentationPageVisibility(
        page_id: string,
        isHidden: boolean
    ): Promise<AxiosResponse<{ page_id: string; is_hidden: boolean }>> {
        return await AxiosInstance.put(
            `/presentation/page/${page_id}`,
            {},
            {
                params: { is_hidden: isHidden },
            }
        );
    }

    async appendCurve(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: Omit<ILine, 'id'>
    ): Promise<AxiosResponse<ILine>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async appendRectangle(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: Omit<IRectangle, 'id'>
    ): Promise<AxiosResponse<IRectangle>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async updateRectangle(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: IRectangle
    ): Promise<AxiosResponse<IRectangle>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async appendArrow(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: Omit<IArrow, 'id'>
    ): Promise<AxiosResponse<IArrow>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async updateArrow(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: IArrow
    ): Promise<AxiosResponse<IArrow>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async appendLine(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: Omit<ILine, 'id'>
    ): Promise<AxiosResponse<ILine>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async updateLine(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: ILine
    ): Promise<AxiosResponse<ILine>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async appendDottedLine(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: Omit<ILine, 'id'>
    ): Promise<AxiosResponse<ILine>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async updateDottedLine(
        pageId: string,
        screenWidth: number,
        screenHeight: number,
        data: ILine
    ): Promise<AxiosResponse<ILine>> {
        return await AxiosInstance.put(
            `/presentation/pages/${pageId}/shapes`,
            { ...data },
            { params: { screenWidth, screenHeight } }
        );
    }

    async changeFigureColor(
        pageId: string,
        figureId: string,
        type: PresentationFiguresEnum,
        color: string
    ) {
        return await AxiosInstance.patch(
            `/presentation/pages/${pageId}/shapes/${figureId}`,
            { type, color }
        );
    }

    async deleteFigure(
        pageId: string,
        figure: { id: string; type: PresentationFiguresEnum }
    ): Promise<
        AxiosResponse<{
            httpCode: number;
            message: 'string';
            name: 'string';
        }>
    > {
        return await AxiosInstance.delete(
            `/presentation/pages/${pageId}/shapes/${figure.id}`,
            {
                params: { type: figure.type },
            }
        );
    }
}

export const presentationAPIs = new PresentationApis();
