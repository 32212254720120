import { ICreatePatient, IPatient } from '@interfaces/patient.interface';
import { AxiosResponse } from 'axios';
import { AxiosInstance } from './axiosInstance';

export class DoctorAPIs {
    async createPatient(data: ICreatePatient): Promise<
        AxiosResponse<{
            message: string;
            patient: IPatient;
        }>
    > {
        return await AxiosInstance.post('/doctor/patient', data);
    }

    async getPatients(): Promise<
        AxiosResponse<{ is_restricted: boolean; patients: IPatient[] }>
    > {
        return await AxiosInstance.get<{
            is_restricted: boolean;
            patients: IPatient[];
        }>('v1/doctor/patients');
    }

    async getArchivedPatients(): Promise<AxiosResponse<IPatient[]>> {
        return await AxiosInstance.get<IPatient[]>('/doctor/archived/patients');
    }

    async getActivePatients(): Promise<AxiosResponse<IPatient[]>> {
        return await AxiosInstance.get<IPatient[]>(
            '/doctor/un-archived/patients'
        );
    }

    async deletePatient(id: string): Promise<AxiosResponse> {
        return await AxiosInstance.put(`/patient/update-status/${id}`, {
            is_archived: true,
        });
    }

    async unarchivePatient(id: string): Promise<AxiosResponse> {
        return await AxiosInstance.put(`/patient/update-status/${id}`, {
            is_archived: false,
        });
    }

    async getOrCreateLink(
        presentationId: string,
        patientId: string
    ): Promise<AxiosResponse<{ link: string }>> {
        return await AxiosInstance.put('/doctor/to-published', {
            presentationId: presentationId,
            patientId: patientId,
        });
    }
}

export const doctorAPIs = new DoctorAPIs();
