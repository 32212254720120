import { AxiosResponse } from 'axios';
import { AxiosInstance } from '@APIs/axiosInstance';
import { IUserProfile } from '@interfaces/authentication';
import { resizeFile } from '@utils/compress';

export class ProfileAPIs {
    async updateProfile(
        data: IUserProfile
    ): Promise<AxiosResponse<IUserProfile>> {
        return await AxiosInstance.put('/profile', data);
    }

    async uploadUserAvatar(file: globalThis.File): Promise<AxiosResponse> {
        const newFile = await resizeFile(file);
        const formData = new FormData();

        formData.append('photo', newFile, file.name);

        return await AxiosInstance.post('/profile/avatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            },
            timeout: 0,
        });
    }

    async changePassword(
        currentPassword: string,
        newPassword: string
    ): Promise<AxiosResponse> {
        return await AxiosInstance.put('/profile/change-password', {
            oldPassword: currentPassword,
            newPassword,
        });
    }

    async setPromocode(promocode: string): Promise<AxiosResponse> {
        return await AxiosInstance.post('/profile/promocode', { promocode });
    }
}

export const profileAPIs = new ProfileAPIs();
