import { AxiosResponse } from 'axios';
import { AxiosInstance } from './axiosInstance';

export interface Subscription {
    _id: string;
    title: string;
    duration: number;
    description: string;
    price: number;
}
export class PurchaseAPIs {
    async status(): Promise<
        AxiosResponse<{
            status: boolean;
            date: number;
        }>
    > {
        return await AxiosInstance.get('/purchase/status');
    }

    async new(
        id: string
    ): Promise<AxiosResponse<{ url: string; title: string; price: string }>> {
        return await AxiosInstance.post('/purchase/new', { id: id });
    }

    async subscriptions(): Promise<AxiosResponse<Subscription[]>> {
        return await AxiosInstance.get('/purchase/available');
    }
}

export const purchaseAPIs = new PurchaseAPIs();
