import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '@contexts/AuthContext';

export const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 1000 * 60,
});

AxiosInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

AxiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.httpCode === 401) {
            const { setJWT } = useAuth();
            setJWT(undefined);
        } else if (error.response.status === 500) {
            toast.error('Ошибка сервера');
        }
        return Promise.reject(error);
    }
);
